import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { AppPageProps } from '../../types'
import { BlockList } from '../../components/BlockList'
import { Layout } from '../../components/Layout'
import { LoadingContext } from '../../contexts/LoadingContext'
import { OpticalImage } from '../../components/OpticalImage'
import { PhilosophyBlock } from '../../components/PhilosophyBlock'
import { PhilosophyItem } from '../../components/PhilosophyItem'
import { PhilosophyRelatedList } from '../../components/PhilosophyRelatedList'
import { SectionList } from '../../components/SectionList'
import { SpiritPageQuery } from '../../../types/graphql-types'

export const query = graphql`
  query SpiritPage {
    heroMobile: file(
      relativePath: { eq: "images/philosophy-spirit-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroTablet: file(
      relativePath: { eq: "images/philosophy-spirit-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageHandmade: file(
      relativePath: { eq: "images/philosophy-handmade.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageDistillation: file(
      relativePath: { eq: "images/philosophy-distillation.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageLegend: file(relativePath: { eq: "images/philosophy-legend.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageVillage: file(relativePath: { eq: "images/philosophy-village.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageLiquor: file(relativePath: { eq: "images/philosophy-liquor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landMobile: file(
      relativePath: { eq: "images/philosophy-land-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landTablet: file(
      relativePath: { eq: "images/philosophy-land-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface SpiritPageProps extends AppPageProps {
  data: SpiritPageQuery
}

const SpiritPage: React.FC<SpiritPageProps> = ({
  pathContext: { langKey },
  data: {
    heroMobile,
    heroTablet,
    imageHandmade,
    imageDistillation,
    imageLegend,
    imageVillage,
    imageLiquor,
    landMobile,
    landTablet,
  },
}) => (
  <Layout
    langKey={langKey}
    pageTitle="精神"
    pageDescription="尾鈴山蒸留所では手間のかかる麹や麦芽づくりはすべて手作業でおこないます。職人が室の中に麹を広げ、適温で発酵させます。蒸留酒造りの最先端技術を生かしながらも、手づくりにしか出せない味と品質を追求しています。"
    titleSublabel="精神"
    titleLabel="Spirit"
  >
    <SectionList>
      <BlockList>
        <LoadingContext.Provider value="eager">
          <OpticalImage
            fluidMobile={heroMobile?.childImageSharp?.fluid as FluidObject}
            fluidTablet={heroTablet?.childImageSharp?.fluid as FluidObject}
            sizes="100vw"
          />
        </LoadingContext.Provider>
        <PhilosophyBlock
          title="手作業"
          fluid={imageHandmade?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            尾鈴山蒸留所では手間のかかる麹や麦芽づくりはすべて手作業でおこないます。職人が室の中に麹を広げ、適温で発酵させます。蒸留酒造りの最先端技術を生かしながらも、手づくりにしか出せない味と品質を追求しています。
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="蒸留"
          fluid={imageDistillation?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            蒸留酒を蒸留酒たらしめるもの。それが蒸留という工程です。蒸留とは醪を沸点まで加熱し、出てきた蒸気を冷却させて液体にすること。蒸留によって液体のアルコール濃度が高められ、芳醇な香りが焼酎にしっかりと留まる。蒸留は紀元前から伝わる、酒造りの神秘であり、科学です。
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="尾鈴の神馬伝説"
          fluid={imageLegend?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            尾鈴山の名前はこの地方に伝わる神馬伝説が由来といわれています。いつの頃からか、山麓の牧場にあらわれるようになった一頭の白馬。それは山の神が山麓の神社に参詣する際に用いる馬。ある日、村の若者が天空高く翔ける馬上の山神を仰ぎ見ると、白馬の首の黄金の鈴が左右に振れ、馬のいななきと鈴の冴えた音が八方に響き渡った。このことを知った村人たちは神が白馬とともに帰っていく山を「お（尾）鈴さま」と呼ぶようになったといいます。
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="新しき村"
          fluid={imageVillage?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            尾鈴山の山麓、小丸川右岸沿いにある「新しき村」。この村は大正7年（1918年）に作家武者小路実篤とその同志によって、「人間らしく生きる」「自己を生かす」理想郷の実現をめざして誕生しました。尾鈴山山麓の豊かな環境の中、武者小路実篤は6年間この地に暮らし、農作業のかたわら文筆活動をおこなっていました。
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="新しい蒸留酒"
          fluid={imageLiquor?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            この土地で私たちにしか造れない蒸留酒を造りたい。そのような想いで蒸留酒を造り続けてきた尾鈴山蒸溜所の歴史を振り返ると、それは挑戦の連続であったことにあらためて気がつきます。そもそも焼酎は九州に根づく麹による発酵文化と海外から伝来した蒸留文化が出会うことで生まれ、独自の発展を遂げたもの。今でこそ当たり前のように使われる芋や麦、米なども元をたどれば外来の原料です。そのような長い歴史を経て、知名度や人気が高まった焼酎ですが、私たちは現在の形が完成形であるとは決して思いません。宮崎の豊かな大地や尾鈴山の自然環境とともにさらなる挑戦を続け、変化し続けることで不変の本質が生まれると信じています。発芽した穀物を原料に長期熟成させたウイスキーやさまざまなボタニカルの香りをまとったスピリッツもこの地から生まれる新しい蒸留酒として大切に育てていきたい。土地を愛し、さらなる可能性を追求し続ける姿勢こそが焼酎醸造家としての精神であると私たちは考えています。
          </p>
        </PhilosophyBlock>
      </BlockList>
      <PhilosophyRelatedList>
        <PhilosophyItem
          to="/philosophy/land/"
          fluidMobile={landMobile?.childImageSharp?.fluid as FluidObject}
          fluidTablet={landTablet?.childImageSharp?.fluid as FluidObject}
          sublabel="土地"
          label="Land"
        />
      </PhilosophyRelatedList>
    </SectionList>
  </Layout>
)

export default SpiritPage
